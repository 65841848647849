import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PropTypes from "prop-types";

export default function TimeSlot({ onChange, start: initialStart, end: initialEnd }) {
    const [start, setStart] = useState(initialStart || dayjs().startOf('day'));
    const [end, setEnd] = useState(initialEnd || dayjs().startOf('day'));

    useEffect(() => {
        onChange({ start, end });
    }, [start, end]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>

            <Grid container spacing={1} justifyContent="center" alignItems="center">
                <Grid item>
                    <MobileTimePicker
                        ampm={false}
                        label="Inizio"
                        value={dayjs(start)}
                        sx={{ maxWidth: '80px' }}
                        onChange={setStart}
                    />
                </Grid>
                <Grid item>
                    <MobileTimePicker
                        ampm={false}
                        sx={{ maxWidth: '80px' }}
                        label="Fine"
                        value={dayjs(end)}
                        onChange={setEnd}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}


TimeSlot.propTypes = {
    onChange: PropTypes.func,
  };
  