import React from 'react';
import { Typography, Link, Box, Button, Stack } from '@mui/material';
import { Facebook, Instagram } from '@mui/icons-material';
const PromotionalFooter = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        mt: 4,
      }}
    >
      <Typography variant="h6" align="center" gutterBottom>
        Menù offerto da OverlineMenù
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        Vuoi anche tu un menù gratuito come questo?{" "}
        <Link href="/" underline="hover" sx={{ fontWeight: 'bold', color: '#212532', textDecoration:"underline" }}>
          clicca qui
        </Link>
        , bastano solo 2 minuti.
      </Typography>
      <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          href="/"
          sx={{ textTransform: 'none', fontWeight: 'bold' }}
        >
          Iscriviti Ora
        </Button>
      </Stack>
      <Typography variant="body2" align="center" sx={{ mt: 4 }}>
        Seguici sui nostri social per rimanere aggiornato:
      </Typography>
      <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 1 }}>
        <Link href="https://www.facebook.com/share/CdJyUJTXZYgUBjsk/" target="_blank" color="inherit">
          <Facebook fontSize="large" sx={{ color: '#3b5998' }} />
        </Link>
        <Link href="https://www.instagram.com/overline_menu/p/C7gkXZsguYY/" target="_blank" color="inherit">
          <Instagram fontSize="large" sx={{ color: '#E4405F' }} />
        </Link>
      </Stack>
    </Box>
  );
};

export default PromotionalFooter;
