import React, { useContext } from "react";
import { UserContext } from "../../store/UserContext";
import { Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { googleLogout } from "@react-oauth/google";
import LoginIcon from "@mui/icons-material/Login";

const Login = () => {
  const {
    setOpenLoginModal,
    userObject,
    setUserObject,
    setLogged,
    setUserNotifications,
    setToken,
  } = useContext(UserContext);

  const handleLoginClick = () => {
    setOpenLoginModal(true);
  };

  function handleSignOut(event) {
    googleLogout();
    localStorage.removeItem("userObject");
    localStorage.removeItem("token");
    localStorage.removeItem("userLicenses");
    localStorage.removeItem("userNotifications");
    setUserObject({});
    setLogged(false);
    setUserNotifications({});
    setToken(undefined);
  }
  const buttonStyle={
  boxShadow:0,borderRadius:'0px',backgroundColor:'white',color:'black',
  "&:hover": {
    bgcolor: "#f0f0f0",
    color: "black",
    boxShadow:0
  },
  }
  return (
    <>
      {Object.keys(userObject).length > 0 ? (
        <Button
          startIcon={<LogoutIcon />}
          fullWidth
          variant={"contained"}
          onClick={() => handleSignOut()}
          sx={buttonStyle}
        >
          Disconnetti
        </Button>
      ) : (
        <Button
          startIcon={<LoginIcon />}
          sx={buttonStyle}
          fullWidth
          variant="contained"
          onClick={handleLoginClick}
        >
          Accedi
        </Button>
      )}
    </>
  );
};

export default Login;
