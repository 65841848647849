import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Typography, Box, Button, Tabs, Tab, Alert, Stack, Switch, FormControlLabel
} from '@mui/material';
import PropTypes from "prop-types";

import AddRoomDialog from './Dialogs/AddRoomDialog';
import TableLegend from './TableLegend';
import RenderAccordionDetails from './RenderAccordionDetails';
import InfoMark from '../Forms/InfoMark';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

import { saveToLocal, loadFromLocal } from '../Functions/storageUtils';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}




export default function Tables({ initialTables, draggable = false, onChangeTables, onClick }) {
    const [localTables, setLocalTables] = useState(initialTables);

    const [shapeView, setShapeView] = useState(loadFromLocal('shapeView', true));

    const [inProgressCount, setInProgressCount] = useState(0)
    const [productToDeliverCount, setProductToDeliverCount] = useState(0)

    function countTableStatus() {
        // Iterare attraverso ciascuna stanza
        let inProgressCountTemp = 0
        let productToDeliverCountTemp = 0
        initialTables.forEach(room => {
            // Iterare attraverso ciascun tavolo nella stanza
            console.log('room:', room)
            room.initialTables.forEach(table => {
                // Controllare se il tavolo ha status "IN_PROGRESS"
                console.log('table:', table)
                if (table.status === "IN_PROGRESS") {
                    inProgressCountTemp++;
                }

                // Controllare se il tavolo ha productToDeliver = true
                if (table.productToDeliver === true) {
                    productToDeliverCountTemp++;
                }
            });
        });
        console.log({ inProgressCount, productToDeliverCount })
        setInProgressCount(inProgressCountTemp)
        setProductToDeliverCount(productToDeliverCountTemp)
    }

    const handleToggle = () => {
        const newState = !shapeView;
        // Salva il nuovo stato in localStorage
        saveToLocal('shapeView', newState);
        setShapeView(newState);
    };

    useEffect(() => {
        setLocalTables(initialTables);
        countTableStatus()
    }, [initialTables]);

    const theme = useTheme();

    const [addDialogOpen, setAddDialogOpen] = useState(false);

    const handleOpenAddDialog = () => {
        setAddDialogOpen(true);
    };

    const handleCloseAddDialog = () => {
        setAddDialogOpen(false);
    };

    const handleAddRoom = (roomName) => {
        const updatedTables = [...localTables, { roomName, initialTables: [] }];
        setLocalTables(updatedTables);
        onChangeTables(updatedTables);
    };

    const [value, setValue] = useState(0); // per i Tabs

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    const pluralize = (count, singular, plural) => {
        return count === 1 ? singular : plural;
    };

    const renderAlert = () => {
        if (productToDeliverCount > 0) {
            return (
                <Alert severity='warning'  icon={<RoomServiceIcon fontSize="inherit" />}>
                    {pluralize(productToDeliverCount, `C'è ${productToDeliverCount} tavolo con prodotti da consegnare`, `Ci sono ${inProgressCount} tavoli con prodotti da consegnare`)} 
                </Alert>
            );
        } else if (inProgressCount > 0) {
            return (
                <Alert severity='info'  icon={<SettingsRoundedIcon fontSize="inherit" className='icn-spinner' />}>
                    {pluralize(inProgressCount, `C'è ${inProgressCount} tavolo con ordini in corso`, `Ci sono ${inProgressCount} tavoli con ordini in corso`)} 
                </Alert>
            );
        }
    };

    return (
        <Box>
            <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                <InfoMark
                    color='black'
                    tooltipContent={
                        <div sx={{ width: '100%' }}>
                            <Typography variant='body' align='left'><b>Legenda dei tavoli</b></Typography>
                            <TableLegend />
                            <Typography variant='body' component='div' align='left'>
                                Ogni tavolo ha diverse proprietà come la capacità (numero di posti a sedere), la forma (rotonda, quadrata, ecc.), e lo stato attuale indicato dal colore (es. occupato, libero).
                            </Typography>
                            <Typography variant='body' component='div' align='left'>
                                <b> Nome del tavolo:</b> Visualizzato nell'angolo in alto a sinistra del tavolo, su uno sfondo nero semitrasparente.
                            </Typography>
                            <Typography variant='body' component='div' align='left'>
                                <b> Nome della prenotazione:</b> Se il tavolo è prenotato, il nome della persona che ha effettuato la prenotazione appare nell'angolo in basso a destra, anch'esso su uno sfondo nero semitrasparente.
                            </Typography>
                            <Typography variant='body' component='div' align='left'>
                                <b>  Capacità:</b> Il numero grande al centro del tavolo indica la sua capacità o il numero di posti a sedere disponibili.
                            </Typography>
                            <Typography variant='body' component='div' align='left'>
                                <b>  Icona campanello:</b> Se ci sono prodotti da consegnare al tavolo, vedrai un'icona (che assomiglia a un campanello) pulsare accanto al numero della capacità.
                            </Typography>

                        </div>
                    }
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={shapeView}
                            onChange={handleToggle}
                            name="shapeViewToggle"
                            color="primary"
                        />
                    }
                    label={shapeView ? "Forme visualizzate" : "Forme nascoste"}
                />


            </Stack>
            {renderAlert()}


            <Box
                sx={{
                    borderRadius: '5px 5px 0px 0px',
                    background: 'linear-gradient(0deg, #e3d8e6, #bcb8be)',
                    padding: '5px',
                    boxShadow: 1,
                    margin: '5px 0px 0px 0px'
                }}>
                <Tabs value={value} onChange={handleChange} 
                                    aria-label="Tables Tabs" 
                                   variant="scrollable"
                                   scrollButtons
                                   allowScrollButtonsMobile
                    sx={{
                        minHeight: 'auto',
                        '& .MuiTabs-scroller':{
                            height:'fit-content'
                        },
                        '& .MuiTabs-indicator': {
                            height: '100%',
                            borderRadius: '10px',
                            zIndex: 1,
                            backgroundColor: '#6a5872c9',
                        },
                    }}>
                    {localTables.map((room, roomIndex) => (
                        <Tab key={roomIndex} label={`${room.roomName} (${room.initialTables.length} tables)`}
                            sx={{
                                borderLeft:roomIndex>0 && 'solid 1px #afaab0',
                                minHeight: '32px', // Ridotto ulteriormente
                                padding: '0px 5px', // Ridotto ulteriormente
                                margin:'0px 0px 0px 2px',
                                textTransform: 'initial',
                                minWidth: 0,
                                '& .MuiTab-wrapper': {
                                    fontWeight: 'normal',
                                    letterSpacing: 0.5,
                                    color: '#ffffff'
                                },
                                '@media (min-width: 960px)': {
                                    minWidth: 0,
                                },
                                color: 'black', // corretto il typo 'balck'
                                '&.Mui-selected': {
                                    color: '#ffffff',
                                    zIndex: 2
                                },
                            }} />
                    ))}
                </Tabs>
            </Box>
            {localTables.map((room, roomIndex) => (
                <div key={roomIndex}>
                    <CustomTabPanel value={value} index={roomIndex}>
                        <RenderAccordionDetails draggable={draggable} shapeView={shapeView} room={room} roomIndex={roomIndex} localTables={localTables} setLocalTables={setLocalTables} onChangeTables={onChangeTables} onClick={onClick} />
                    </CustomTabPanel>
                </div>
            ))}

            {draggable &&
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenAddDialog}
                        sx={{ margin: '10px' }}
                    >
                        Aggiungi Stanza
                    </Button>

                    <AddRoomDialog
                        open={addDialogOpen}
                        onClose={handleCloseAddDialog}
                        onAdd={handleAddRoom}
                        existingRoomNames={localTables.map(room => room.roomName)}
                    />
                </>
            }
        </Box>
    );
}


Tables.propTypes = {
    onChangeTables: PropTypes.func,
    draggable: PropTypes.bool,
    Tables: PropTypes.array,
    onClick: PropTypes.func,
    shapeView: PropTypes.bool
};
