import React, { useContext } from "react";
import { UserContext } from "../../store/UserContext";
import { Dialog, DialogActions, DialogTitle, Button } from "@mui/material";
import LoginOptions from "./LoginOptions";

const LoginModal = () => {
  const {
    openLoginModal,
    setOpenLoginModal,
  } = useContext(UserContext);


  const handleClose = () => {
    setOpenLoginModal(false);
  };

  return (
    <Dialog open={openLoginModal} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Opzioni di Login</DialogTitle>
      <LoginOptions/>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginModal;
