// LoginOptions.js
import React, { useContext } from "react";
import { DialogContent, DialogContentText, Box } from "@mui/material";
import { jwtDecode as jwt_decode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";

import { UserContext } from "../../store/UserContext";

const LoginOptions = ({}) => {
  const { setUserObject, setToken, setOpenLoginModal } =
    useContext(UserContext);

  function handleGoogleLogin(response) {
    var userObjectTemp = jwt_decode(response.credential);
    localStorage.setItem("userObject", JSON.stringify(userObjectTemp));
    setUserObject(userObjectTemp);
    localStorage.setItem("token", response.credential);
    setToken(response.credential);
    setOpenLoginModal(false);
  }

  return (
    <DialogContent dividers sx={{ overflow: "hidden" }}>
      <DialogContentText sx={{ marginBottom: "16px" }}>
        Per procedere, è necessario effettuare l'accesso tramite Google. Questo
        ci permette di ottenere le informazioni necessarie per migliorare la tua
        esperienza, come nome, email e foto profilo. Per maggiori dettagli,
        consulta la nostra <a href="/privacy-policy">Privacy Policy</a> e i{" "}
        <a href="/terms-of-service">Termini e Condizioni</a>. Utilizziamo Google
        come autenticatore per garantire la sicurezza del tuo account e
        facilitare l'accesso senza la necessità di una password.
      </DialogContentText>
      <Box sx={{ width: "100%", textAlign: "-webkit-center" }}>
        <GoogleLogin
          onSuccess={(response) => {
            handleGoogleLogin(response);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
          auto_select
          useOneTap
          style={{ maxWidth: "400px" }}
        />
      </Box>
      {/* Puoi aggiungere altre opzioni di login qui */}
    </DialogContent>
  );
};

export default LoginOptions;
