import React from "react";
import { QRCode } from "react-qrcode-logo";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

export default function QrCodeComponent({
  baseUrl,
  local_id,
  booking_id,
  local_logo,
  size = 250,
  bgColor = "rgba(255, 255, 255, 0.7)", // Semi-transparent background color
  fgColor,
  qrStyle = "dots",
  eyeRadius,
  logoWidth = 50,
  logoHeight = 50,
  logoOpacity = 1, // Full opacity for the logo
  removeQrCodeBehindLogo = false,
  enableDownload = false,
  logoPosition = "foreground", // New prop for logo position
  ...props
}) {
  const theme = useTheme();

  const downloadQRCode = () => {
    const canvas = document.querySelector("canvas");
    const url = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = url;
    link.download = `QRCode-${local_id}-${booking_id}.png`;
    link.click();
  };

  return (
    <div
      style={{
        display: "flex",  // Flexbox is used for centering
        justifyContent: "center",  // Horizontally center the QR code component
        alignItems: "center",  // Vertically center the QR code component (if necessary)
        height: "100%",  // Adjust as necessary
        width: "100%",  // Adjust as necessary
      }}
    >
      <div
        style={{
          position: "relative",
          height: `${size+20}px`,
          width: `${size+20}px`,
        }}
      >
        {logoPosition === "background" && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              display: "flex",
              padding:'00px',
              justifyContent: "center",
              alignItems: "center",
              zIndex: 0, // Ensure the logo is behind the QR code
            }}
          >
            <img
              src={local_logo}
              alt="logo"
              style={{
                width: `${size+20}px`,
                height: `${size+20}px`,
                objectFit: "cover",
                opacity: logoOpacity,
                borderRadius:'5px',
              }}
            />
          </div>
        )}

        <QRCode
          style={{ zIndex: 1, position: "relative" }} // Ensure the QR code is above the background logo
          value={`${baseUrl}/bookings/${local_id}/${booking_id}`}
          size={size}
          qrStyle={qrStyle}
          bgColor={bgColor}
          fgColor={fgColor || theme.palette.background.paper}
          eyeRadius={
            eyeRadius || [
              { outer: [20, 20, 20, 20], inner: [20, 20, 20, 20] },
              { outer: [20, 20, 20, 20], inner: [20, 20, 20, 20] },
              { outer: [20, 20, 20, 20], inner: [20, 20, 20, 20] },
            ]
          }
          removeQrCodeBehindLogo={removeQrCodeBehindLogo}
          {...props}
        />

        {logoPosition === "foreground" && (
          <div
            style={{
              position: "absolute",
              top: `calc(50% - ${logoHeight / 2}px)`,
              left: `calc(50% - ${logoWidth / 2}px)`,
              width: `${logoWidth}px`,
              height: `${logoHeight}px`,
              borderRadius: "50%",
              overflow: "hidden",
              boxShadow: "0 0 5px rgba(0,0,0,0.3)", // Optional shadow for better visibility
              zIndex: 2, // Ensure the logo is above the QR code
            }}
          >
            <img
              src={local_logo}
              alt="logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        )}

        {enableDownload &&
        <Button startIcon={<DownloadIcon/>} onClick={downloadQRCode}>Download QR Code</Button>}
      </div>
    </div>
  );
}

QrCodeComponent.propTypes = {
  size: PropTypes.number,
  bgColor: PropTypes.string,
  fgColor: PropTypes.string,
  qrStyle: PropTypes.oneOf(["dots", "squares", "fluid"]),
  eyeRadius: PropTypes.array,
  logoWidth: PropTypes.number,
  logoHeight: PropTypes.number,
  logoOpacity: PropTypes.number,
  removeQrCodeBehindLogo: PropTypes.bool,
  enableDownload: PropTypes.bool,
  logoPosition: PropTypes.oneOf(["foreground", "background"]), // New prop type
};
