import React, { useEffect, useContext } from "react";
import {
  Button,
  Typography,
  Grid,
  Box,
  Stack,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { UserContext } from "../../../store/UserContext";
import { getImageType } from "../../../story-components/components";
import OpenTimesDisplay from "./EditLocal/components/OpenTimesDisplay";
import BusinessCard from "./BusinessCard";

import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation, Trans } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet-async";

import LocalContactForm from "./Contact/LocalContactForm";

export default function LocalLandingPage() {
  const { IsAllowed, GetLocalIdParams, localData } = useContext(UserContext);

  const { local_id, local_name } = useParams();
  const { i18n } = useTranslation();
  const language = i18n.language ? i18n.language.toLowerCase() : "it";

  let navigate = useNavigate();
  const location = useLocation();
  const lastPage = location.state?.from;
  // Verifica se l'URL della pagina precedente corrisponde al pattern desiderato
  const showGoBackMenu =
    lastPage && /\/local\/Menu\/[^/]+\/[^/]+/.test(lastPage);

  function formatDate(dateStr) {
    if (!dateStr) return "Data non disponibile";
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  }

  function ClosingDatesAccordion({ dates = [] }) {
    if (dates.length === 0) {
      return null;
    }

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Prossimo giorno di chiusura:{" "}
            {formatDate(dates[0].local_closing_start_date)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Alert severity="info">Prossime date di chiusura:</Alert>

          <Typography component="div">
            <ul>
              {dates.map((date, index) => (
                <li key={index}>
                  {JSON.stringify(date.local_closing_start_date) ===
                  JSON.stringify(date.local_closing_end_date) ? (
                    <>{formatDate(date.local_closing_start_date)}</>
                  ) : (
                    <>
                      Dal: {formatDate(date.local_closing_start_date)}, al:{" "}
                      {formatDate(date.local_closing_end_date)}
                    </>
                  )}
                </li>
              ))}
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  }

  useEffect(() => {
    GetLocalIdParams(local_id);
  }, []);
  const canonicalUrl = `https://overlinemenu.it/local_landing_page/${localData?.local_id}`;
  return (
    <div>
      <Helmet>
        <title>{localData?.local_name}</title>
        <meta name="title" content={localData?.local_name} />
        <meta property="og:title" content={localData?.local_name} />
        <meta
          property="og:description"
          content={`${localData?.local_name} - ${localData?.local_city} - ${localData?.local_address}. Orari, take away, delivero, consegne, aperture, giorni di chiusura, giorni di apertura`}
        />
                {localData?.local_logo && (
          <meta property="og:image" content={localData.local_logo} />
        )}
        {localData?.local_logo && (
          <link
            rel="icon"
            href={localData.local_logo}
            type={getImageType(localData.local_logo)}
          />
        )}
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      {Object.keys(localData).length === 0 ? (
        <div>
          <Typography variant="h1" color="text.background">
            Loading...
          </Typography>
        </div>
      ) : (
        <div>
          <BusinessCard
            type={localData.business_card_type}
            image={localData.local_cover}
            name={localData.local_name}
            city={localData.local_city}
            cap={localData.local_cap}
            address={localData.local_address}
            phone={localData.local_phone}
            whatsapp={localData.local_whatsapp}
            facebook={localData.facebook}
            latitude={localData.local_latitude}
            longitude={localData.local_longitude}
            instagram={localData.instagram}
            isOpen={localData.isOpen}
            navigateToLandingPage={false}
            table_booking={localData.table_booking}
          />
          <Box sx={{ padding: "5px" }}>
            {IsAllowed(local_id, ["MANAGER"]) && (
              <Stack direction="row" spacing={1} style={{ marginTop: "5px" }}>
                <Button
                  fullWidth
                  startIcon={<SettingsRoundedIcon/>}
                  variant="contained"
                  onClick={() => navigate("/edit_local/" + local_id)}
                >
                  <Trans>Impostazioni</Trans>
                </Button>
              </Stack>
            )}

            {showGoBackMenu ? (
              <Button
                sx={{ marginTop: "10px" }}
                startIcon={<ArrowBackIcon />}
                fullWidth
                variant="contained"
                onClick={() => navigate(-1)}
              >
                <Trans>Torna indietro</Trans>
              </Button>
            ) : (
              <Button
                fullWidth
                sx={{ marginTop: "5px" }}
                startIcon={<AutoStoriesIcon />}
                variant="contained"
                onClick={() => navigate(`/local/Menu/${local_id}`)}
              >
                {" "}
                <Trans>Vai al menù</Trans>
              </Button>
            )}

            {localData.local_info && localData.local_info?.[language] && (
              <Alert
                severity="info"
                sx={{ marginTop: "10px", justifyContent: "center" }}
              >
                {localData.local_info?.[language]}
              </Alert>
            )}

            {localData.local_header_info && (
              <Typography
                variant="body"
                component="div"
                align="center"
                color="text.background"
                sx={{ marginTop: "10px" }}
              >
                {localData.local_header_info[language]}
              </Typography>
            )}
            {localData.plan_value > 2 && (
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}></Grid>
                {localData.delivery && (
                  <Grid item xs={12} md={4}>
                    <Button
                      style={{ height: "100%" }}
                      startIcon={<DeliveryDiningIcon />}
                      disabled={!localData.delivery}
                      fullWidth
                      variant="contained"
                      onClick={() =>
                        navigate(
                          "/local/menu/" +
                            local_id +
                            "/" +
                            local_name +
                            "/Delivery"
                        )
                      }
                    >
                      <Trans>Ordina e ricevi direttamente a casa</Trans>
                    </Button>
                  </Grid>
                )}
                {localData.take_away && (
                  <Grid item xs={12} md={4}>
                    <Button
                      style={{ height: "100%" }}
                      startIcon={<ShoppingBasketIcon />}
                      disabled={!localData.take_away}
                      fullWidth
                      variant="contained"
                      onClick={() =>
                        navigate(
                          "/local/menu/" +
                            local_id +
                            "/" +
                            local_name +
                            "/Take Away"
                        )
                      }
                    >
                      <Trans>Ordina e ritira al locale</Trans>
                    </Button>
                  </Grid>
                )}

              </Grid>
            )}
            {localData.local_description && (
              <Typography
                variant="body"
                component="div"
                align="center"
                color="text.background"
                sx={{ marginTop: "10px" }}
              >
                {localData.local_description[language]}
              </Typography>
            )}

            {localData.plan_value > 1 && (
              <>
                <Typography color="text.background" variant="h6">
                  Orari di apertura
                </Typography>
                <OpenTimesDisplay schedules={localData.local_closing_times} />
              </>
            )}
            {localData.plan_value > 1 && (
              <ClosingDatesAccordion dates={localData.local_closing_dates} />
            )}

            {localData.local_footer_info && (
              <Typography
                variant="body"
                component="div"
                align="center"
                color="text.background"
                sx={{ marginTop: "10px" }}
              >
                {localData.local_footer_info[language]}
              </Typography>
            )}

            {localData.contactFormStatus && <LocalContactForm />}
          </Box>
        </div>
      )}
    </div>
  );
}
