import React, { useState } from 'react';
import HttpsIcon from '@mui/icons-material/Https';
import { ButtonBase, Box, Snackbar, Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import PropTypes from 'prop-types';

function Locker({ locked, message, children, content }) {
  const [openToast, setOpenToast] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = () => {
    if (typeof content === 'undefined' || message) {
      setOpenToast(true);
    } else {
      setOpenDialog(true);
    }
  }

  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  if (locked) {
    return (
      <>
        <Box onClick={handleClick} sx={{ borderRadius: '5px', width: '100%', position: 'relative' }} style={{ background: 'transparent' }}>
        <div style={{ filter: locked ? 'blur(0.5px)' : 'none', pointerEvents: 'none', width: '100%' }}>

            {children}
          </div>
          <div style={{
            filter: 'none', position: 'absolute',
            maxWidth: '100%',
            maxHeight: '100%',
            bottom: '0px', right: '0px'
          }}>
            <Box sx={{ boxShadow: 1 }} style={{
              borderRadius: '50%',
              background: 'transparent',
              backgroundColor: '#ffffffb5',
              padding: '10px',
              filter: 'blur(0px)'
            }}>
              <div style={{ filter: 'none' }}>
                <HttpsIcon fontSize="small" />
              </div>
            </Box>
          </div>
        </Box>

        <Snackbar
          open={openToast}
          autoHideDuration={6000}
          onClose={handleCloseToast}
          message={message}
          action={
            <React.Fragment>
              {/* Aggiungi eventuali azioni al toast qui */}
            </React.Fragment>
          }
        />

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            {content}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } else {
    return children;
  }
}

Locker.propTypes = {
  locked: PropTypes.bool,
  message: PropTypes.string,
  children: PropTypes.node.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ])
};

export default Locker;
