import React, { createContext, useRef, useState, useMemo, useEffect } from 'react'
//creazione del context a cui viene aggiunto il valore dal provider
export const BasketContext = createContext({});


//Creazione del provider da inserire come padre per poi ottenere il context nei componenti figli

export const BasketContextProvider = ({ children }) => {

  //----- basket -------//

  let [basket, setBasket] = useState([]);
  let [basketGrouped, setBasketGrouped] = useState({})
  let [basketInfo, setBasketInfo] = useState({})

  const updateTimer = useRef(null); // useRef per mantenere il timer
  const sessionCodeRef = useRef(`${Math.random().toString(36).substring(2, 15)}`); // Utilizza useRef per il sessionCode


  useEffect(() => {
    const calculateTotal = (basket) => {
      let totalInfo = {};

      Object.keys(basket).forEach(local_id => {
        totalInfo[local_id] = basket[local_id].reduce(
          (total, product) => {
            total.quantity += product.quantity;
            total.price += product.product_price * product.quantity;
            return total;
          },
          { quantity: 0, price: 0 } // Valore iniziale
        );
      });

      return totalInfo;
    };

    const sendBasketUpdate = () => {
      // Genera un codice sessione unico per il giorno corrente
      const hasNonEmptyBasket = Object.values(basket).some(items => Array.isArray(items) && items.length > 0);
      if (hasNonEmptyBasket) {
        const body = { sessionCode: sessionCodeRef.current, basket }
        // Preparazione dell'URL e delle opzioni per la richiesta fetch
        const url = '/api/menu/add-to-basket';
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        };

        // Esecuzione della chiamata fetch senza gestire la risposta o l'errore

        fetch(url, options).then(response => {
          // Potenzialmente, potresti voler fare qualcosa con la risposta qui, ma se non vuoi feedback...
        }).catch(error => {
          // ...o qui, in caso di errore, non fare nulla
        });
        // Qui andrebbe la chiamata al server con i dati del carrello e il sessionCode

      };
    }

    if (updateTimer.current !== null) {
      clearTimeout(updateTimer.current); // Resetta il timer se c'è già un aggiornamento in sospeso
    }

    updateTimer.current = setTimeout(() => {
      sendBasketUpdate(); // Invoca la funzione per l'invio dei dati al server
      updateTimer.current = null; // Resetta il riferimento al timer dopo l'invio
    }, 5000); // Imposta il timer a 5 secondi

    // Utilizzo della funzione
    const totals = calculateTotal(basket);
    // total è ora un oggetto in cui ogni chiave è un local_id e il valore è un oggetto con la quantità totale e il prezzo totale
    setBasketInfo(totals);

  }, [basket]);

  const providerValue = useMemo(() => ({ basket, setBasket, basketGrouped, basketInfo }),
    [basket, setBasket, basketGrouped, basketInfo])


  return (
    <BasketContext.Provider value={providerValue} >
      {children}
    </BasketContext.Provider>
  );
}