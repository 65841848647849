import React from "react";
import { FormControl, InputLabel,Select,MenuItem } from "@mui/material";
import dayjs from "dayjs";

const TimeSlotSelector = ({ timeRanges, marginBeforeClosing = 15,timeSlot=30, onTimeSlotSelect }) => {
  const generateTimeSlots = () => {
    let timeSlots = [];
    
    timeRanges.forEach((range) => {
      let startTime = dayjs(range.start).startOf("minute");
      let endTime = dayjs(range.end).startOf("minute")


      console.log('endTime all inizio:',endTime)
      endTime= endTime.subtract(marginBeforeClosing,'minute') // arrotonda all'ora
      console.log('endTime all inizio2:',endTime)
      while (startTime.isBefore(endTime)) {
        console.log({startTime,timeSlot})
        timeSlots.push(startTime.format("HH:mm"));
        startTime = startTime.add(timeSlot, "minute");
      }
      console.log({endTime,startTime})
    });

    return timeSlots;
  };

  const timeSlots = generateTimeSlots();

  return (
    <FormControl fullWidth>
      <InputLabel>Orario</InputLabel>
      <Select onChange={(e) => onTimeSlotSelect(e.target.value)} label="Orario">
        {timeSlots.map((time, index) => (
          <MenuItem key={index} value={time}>
            {time}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimeSlotSelector;
