import React, { useContext, useState } from "react";
import { UserContext } from "../store/UserContext";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import SearchIcon from "@mui/icons-material/Search";
import ReceiptIcon from "@mui/icons-material/Receipt";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { Stack, Box, Typography, Button } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CookieIcon from "@mui/icons-material/Cookie";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import NotesIcon from "@mui/icons-material/Notes";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LegalInfo from "./LegalInfo";

import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import ListIcon from "@mui/icons-material/List";
import { useTranslation, Trans } from "react-i18next";
import EventIcon from '@mui/icons-material/Event';
import { SelectButton } from "../story-components/components";
import NotificationToggle from "./NotificationToggle ";

export default function DrawerApp({ drawerOpen, setDrawerOpen }) {
  const theme = useTheme();
  let navigate = useNavigate();

  const [openCookie, setOpenCookie] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const { logged, user_licenses, isAdmin, localData } = useContext(UserContext);
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleNavigateAndReload = () => {
    // Naviga alla pagina desiderata con un timestamp come query param per bypassare la cache
    navigate("/install-app/" + new Date().getTime());

    // Forza il reload della pagina dal server, bypassando la cache del browser
    window.location.reload(true);
  };

  const LogoElement = () => (
    <Box
      onClick={() => {
        navigate(`/local_landing_page/${localData.local_id}`, {
          state: { from: "/local/Menu/:local_id/:table_reference" },
        });
        setDrawerOpen(false);
      }}
      sx={{
        width: "100%",
        minHeight: "125px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: "35vh",
        marginBottom: "0px",
        padding: "0px", // Aggiungi un po' di padding per distanziare il contenuto dai bordi
      }}
    >
      {localData.local_logo && localData.local_logo !== "" && (
        <img
          src={localData.local_logo}
          alt={localData.local_name}
          style={{
            maxHeight: "125px",
            maxWidth: "50vw",
            width: "100%",
            objectFit: "cover",
          }}
        />
      )}

      <Typography variant="body2" color="black" component="div">
        {localData.local_name}
      </Typography>
    </Box>
  );
  const AppInfoElement = () => <div>By Overline</div>;

  const firstDividerLogged = [
    ["I miei ordini", "/my_orders", <ReceiptIcon />],
    ["I miei locali", "/my_locals", <ListIcon />],
    ["Le mie prenotazioni", "/my_bookings", <EventIcon />],
    ["I miei coupon", "/my_coupons", <CreditCardIcon />],
    ["Cerca locali", "/Locals", <SearchIcon />],
    ["Home", "/Home", <HomeIcon />],
    ["Contattaci", "/contact_us", <ContactSupportIcon />],
    ["Su di noi", "/about_us", <InfoIcon />],
  ];

  const firstDividerUnLogged = [
    ["Home", "/home", <HomeIcon />],
    /*['Lascia una recensione', `/local_landing_page/${localData.local_id}`, <HomeIcon />],
      ['Condividi la tua esperienza', `/local_landing_page/${localData.local_id}`, <HomeIcon />]*/
  ];

  const currentDivider = logged ? firstDividerLogged : firstDividerUnLogged;

  const drawer = (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{ height: "100%", maxWidth: "80vw" }}
      alignItems="flex-start"
      spacing={2}
    >
      <Box>
        {localData.local_logo && localData.local_logo !== "" && <LogoElement />}
        <List>
          {currentDivider.map(([text, indirizzo, icon], index) => (
            <Link
              id={`drawer-item-${text}`}
              key={"link-logged-" + text}
              className="link-no-decoration"
              to={indirizzo}
              onClick={() => setDrawerOpen(false)}
              style={{
                color: theme.palette.text.primary,
                textDecoration: "none",
              }}
            >
              <ListItem
                className="link-no-decoration"
                button
                key={"list-item-logged" + text}
              >
                {icon}
                <ListItemText primary={<Trans>{text}</Trans>} />
              </ListItem>
            </Link>
          ))}


          {isAdmin && (
            <Link
              key={"link-admin-panel"}
              className="link-no-decoration"
              to={"/admin/console"}
              onClick={() => setDrawerOpen(false)}
              style={{
                color: theme.palette.text.primary,
                textDecoration: "none",
              }}
            >
              <ListItem key={"Admin console"}>
                <AdminPanelSettingsIcon />
                <ListItemText primary={<Trans>Admin</Trans>} />
              </ListItem>
            </Link>
          )}
        </List>
        <Divider />

        <List>
          <ListItem>
            <SelectButton
              fullWidth
              options={["it", "en", "fr", "es", "de", "ja", "zh"]}
              value={i18n.language}
              onChange={changeLanguage}
            />
          </ListItem>
        </List>

        <List>
          <ListItem
            className="link-no-decoration"
            button
            key={"list-item-logged-cookie"}
            onClick={() => setOpenCookie(true)}
          >
            <PrivacyTipIcon />
            <ListItemText primary={<Trans>Privacy e Cookie Policy</Trans>} />
          </ListItem>
          <ListItem
            className="link-no-decoration"
            button
            key={"list-item-logged-terms-of-service"}
            onClick={() => setOpenTerms(true)}
          >
            <NotesIcon />
            <ListItemText primary={<Trans>Termini e condizioni</Trans>} />
          </ListItem>
        </List>
        <List>
          <ListItem>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleNavigateAndReload}
            >
              <Trans>Installa App</Trans>
            </Button>
          </ListItem>
          <ListItem>
            <NotificationToggle />
          </ListItem>
        </List>
      </Box>
      <LegalInfo open={openCookie} setOpen={setOpenCookie} type="privacy" />
      <LegalInfo open={openTerms} setOpen={setOpenTerms} type="terms" />

      <AppInfoElement />
    </Stack>
  );

  return (
    <div>
      <React.Fragment key={"left"}>
        <Drawer
          style={{ zIndex: 1400 }}
          anchor={"left"}
          open={drawerOpen}
          onClose={() => handleDrawerToggle()}
        >
          {drawer}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
