// src/TermsOfService.js
import React from "react";
import PrivacyPolicy from "./PrivacyPolicy";
import { Typography } from "@mui/material";

// Responsabilità per i contenuti caricati dall'utente
const ResponsibilityForUserContent = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Responsabilità per i contenuti caricati dall'utente
    </Typography>

    <Typography variant="body1" paragraph>
      L'utente è l'<strong>unico responsabile</strong> per tutti i contenuti
      che carica, pubblica o rende disponibile attraverso{" "}
      <strong>l'applicazione</strong>.
    </Typography>

    <Typography variant="body2" paragraph>
      Inviando contenuti attraverso <strong>l'applicazione</strong>, l'utente
      dichiara e garantisce di avere tutti i <strong>diritti legali</strong>{" "}
      necessari per farlo e che tali contenuti non violano alcuna legge
      applicabile, diritto d'autore, brevetto, marchio, segreto commerciale o
      diritto di privacy di terzi.
    </Typography>

    <Typography variant="body2" paragraph>
      L'utente accetta di <strong>non caricare, pubblicare</strong> o
      altrimenti rendere disponibile attraverso{" "}
      <strong>l'applicazione</strong> contenuti che:
    </Typography>

    <ul>
      <Typography component="li" variant="body2">
        Siano falsi, ingannevoli, diffamatori, discriminatori, violenti,
        osceni, pornografici, illegali o altrimenti offensivi;
      </Typography>
      <Typography component="li" variant="body2">
        Contengano virus, trojan, worm o altri codici dannosi;
      </Typography>
      <Typography component="li" variant="body2">
        Violino qualsiasi legge locale, nazionale o internazionale
        applicabile.
      </Typography>
    </ul>

    <Typography variant="body2" paragraph>
      <strong>L'applicazione</strong> si riserva il diritto, ma non ha
      l'obbligo, di rivedere, monitorare o rimuovere qualsiasi contenuto
      caricato dagli utenti a sua esclusiva discrezione e senza preavviso.
    </Typography>
  </div>
);

// Modifica dei Termini e Condizioni
const ModificationOfTerms = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Modifica dei Termini e Condizioni
    </Typography>
    <Typography variant="body1" paragraph>
      L'applicazione si riserva il diritto di modificare i termini e
      condizioni in qualsiasi momento. Gli utenti saranno notificati delle
      modifiche sostanziali tramite email o direttamente nell'app nella
      sezione di notifiche. Continuando a utilizzare l'applicazione dopo tali
      modifiche, l'utente accetta i nuovi termini e condizioni.
    </Typography>
  </div>
);

// Condizioni di utilizzo dei Servizi
const TermsOfServiceUsage = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Condizioni di utilizzo dei Servizi. Oggetto del Contratto. Pagamenti
    </Typography>
    <Typography variant="body1" paragraph>
      L'applicazione offre un servizio digitale per attività come ristoranti e
      altre attività commerciali che necessitano di un listino prezzi digitale
      per i clienti. I servizi includono la gestione degli ordini e l'analisi
      dei dati tramite dashboard, disponibili in base al piano scelto.
      L'applicazione è a pagamento e prevede costi mensili o annuali, con
      pagamenti effettuati tramite carta di credito o debito con rinnovo
      automatico.
    </Typography>
  </div>
);

// Variazione dei prezzi
const PriceVariation = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Variazione dei prezzi
    </Typography>
    <Typography variant="body1" paragraph>
      <ul>
        <Typography component="li" variant="body1">
          L'applicazione si riserva il diritto di modificare il prezzo del
          servizio nel tempo, sia in aumento che in diminuzione.
        </Typography>
        <Typography component="li" variant="body1">
          Qualsiasi variazione di prezzo sarà comunicata con un preavviso,
          permettendo all'utente di decidere liberamente se continuare a
          utilizzare il servizio.
        </Typography>
      </ul>
    </Typography>
  </div>
);

// Obblighi dell'Utente Gestore
const UserObligations = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Obblighi dell'Utente Gestore
    </Typography>
    <Typography variant="body1" paragraph>
      Ogni gestore può aggiungere collaboratori con diversi ruoli, come
      manager senior o staff, che possono visualizzare, aggiungere, rimuovere
      o modificare le impostazioni dell'attività o dei prodotti inseriti, in
      base al piano scelto.
    </Typography>
  </div>
);

// Diritti degli Utenti ai sensi del GDPR
const UserRightsUnderGDPR = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Diritti degli Utenti ai sensi del GDPR
    </Typography>
    <Typography variant="body1" paragraph>
      In conformità con il Regolamento Generale sulla Protezione dei Dati (GDPR), gli utenti hanno il diritto di:
    </Typography>
    <ul>
      <Typography component="li" variant="body1">
        <strong>Accedere</strong> ai propri dati personali e ottenere informazioni sul loro trattamento.
      </Typography>
      <Typography component="li" variant="body1">
        Richiedere la <strong>rettifica</strong> dei dati personali inesatti o incompleti.
      </Typography>
      <Typography component="li" variant="body1">
        Richiedere la <strong>cancellazione</strong> dei propri dati personali nei casi previsti dalla legge.
      </Typography>
      <Typography component="li" variant="body1">
        Ottenere la <strong>limitazione</strong> del trattamento dei propri dati personali in determinate circostanze.
      </Typography>
      <Typography component="li" variant="body1">
        Ricevere i propri dati in un formato strutturato e leggibile da dispositivo automatico e, ove tecnicamente fattibile, trasmetterli a un altro titolare del trattamento (<strong>diritto alla portabilità</strong>).
      </Typography>
      <Typography component="li" variant="body1">
        Opporsi al trattamento dei propri dati personali per motivi legittimi.
      </Typography>
      <Typography component="li" variant="body1">
        Non essere soggetti a decisioni basate unicamente su trattamenti automatizzati, inclusa la profilazione, che producano effetti giuridici o incidano significativamente sulla propria persona.
      </Typography>
    </ul>
    <Typography variant="body1" paragraph>
      Per esercitare i propri diritti, gli utenti possono contattarci all'indirizzo email <a href="mailto:info@overlinemenu.it">info@overlinemenu.it</a> o tramite gli appositi form all'interno dell'applicazione.
    </Typography>
  </div>
);


// Licenza di utilizzo
const License = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Licenza di utilizzo
    </Typography>
    <Typography variant="body1" paragraph>
      L'applicazione concede agli utenti una licenza limitata, non esclusiva e
      revocabile per l'utilizzo del servizio. Gli utenti non possono
      riprodurre, distribuire, creare opere derivate o sfruttare
      commercialmente l'applicazione in alcun modo senza previa autorizzazione
      scritta.
    </Typography>
  </div>
);

// Proprietà Intellettuale e Obbligo di Riservatezza
const IntellectualProperty = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Proprietà Intellettuale e Obbligo di Riservatezza
    </Typography>
    <Typography variant="body1" paragraph>
      Tutti i contenuti e i materiali forniti attraverso l'applicazione sono
      di proprietà esclusiva dell'azienda e sono protetti da copyright e altre
      leggi sulla proprietà intellettuale. Gli utenti devono mantenere
      riservate tutte le informazioni sensibili e non divulgare tali
      informazioni a terzi senza autorizzazione.
    </Typography>
  </div>
);

// Esclusione di Garanzia e Limitazioni di Responsabilità
const WarrantyDisclaimer = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Esclusione di Garanzia e Limitazioni di Responsabilità
    </Typography>
    <Typography variant="body1" paragraph>
      L'applicazione viene fornita "così com'è" senza garanzie di alcun tipo.
      L'azienda non sarà responsabile per eventuali danni diretti, indiretti,
      incidentali, speciali o consequenziali derivanti dall'uso o
      dall'impossibilità di utilizzare l'applicazione.
    </Typography>
  </div>
);

// Manleva
const Indemnification = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Manleva
    </Typography>
    <Typography variant="body1" paragraph>
      L'utente accetta di manlevare e tenere indenne l'azienda da qualsiasi
      pretesa, richiesta, danno o perdita, inclusi costi legali, derivanti
      dall'uso improprio dell'applicazione o dalla violazione dei termini e
      condizioni.
    </Typography>
  </div>
);

// Recesso
const Withdrawal = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Recesso
    </Typography>
    <Typography variant="body1" paragraph>
      Gli utenti possono terminare il rinnovo del servizio in qualsiasi
      momento tramite le impostazioni dell'account. Non sono previste
      possibilità di recedere o sospendere il contratto in corso.
    </Typography>
  </div>
);

// Privacy
const Privacy = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Privacy
    </Typography>
    <Typography variant="body1" paragraph>
      L'applicazione raccoglie dati personali, tra cui email e informazioni
      fornite durante l'iscrizione. Questi dati sono utilizzati per scopi di
      monitoraggio della sicurezza e stabilità del servizio. Tutte le chiamate
      con il server sono registrate ai fini di log.
    </Typography>
  </div>
);

// Legge Applicabile e Foro Competente
const GoverningLaw = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Legge Applicabile e Foro Competente
    </Typography>
    <Typography variant="body1" paragraph>
      I termini e condizioni sono regolati dalla legge italiana. Qualsiasi
      controversia derivante dai termini e condizioni sarà di competenza
      esclusiva del foro di Torino.
    </Typography>
  </div>
);

// Sicurezza dei Dati
const DataSecurity = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Sicurezza dei Dati
    </Typography>
    <Typography variant="body1" paragraph>
      Adottiamo misure di sicurezza tecniche e organizzative per proteggere i
      dati personali degli utenti da accessi non autorizzati, perdita o
      distruzione. Utilizziamo servizi cloud per la gestione e la sicurezza
      dei dati, con backup automatici e controlli di accesso rigorosi. Il
      servizio è erogato unicamente tramite connessione sicura HTTPS,
      garantendo la riservatezza delle informazioni trasmesse.
    </Typography>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Procedura in Caso di Violazione dei Dati
    </Typography>
    <Typography variant="body1" paragraph>
      In caso di violazione dei dati personali, ci impegniamo a notificare tempestivamente gli utenti interessati e l'Autorità Garante per la Protezione dei Dati Personali entro i termini previsti dalla legge. Adotteremo tutte le misure necessarie per limitare qualsiasi impatto sugli utenti e per prevenire future violazioni.
    </Typography>
  </div>
);

// Utilizzo di Servizi di Terze Parti
const ThirdPartyServices = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Utilizzo di Servizi di Terze Parti
    </Typography>
    <Typography variant="body1" paragraph>
      Per fornire il nostro servizio, utilizziamo servizi di terze parti
      affidabili per l'autenticazione degli utenti e i pagamenti elettronici,
      come ad esempio Google per l'autenticazione e Stripe per i pagamenti.
      Questi fornitori trattano i dati personali degli utenti esclusivamente
      per nostro conto e secondo le nostre istruzioni, in conformità con le
      normative vigenti sulla protezione dei dati.
    </Typography>
  </div>
);

// Politica sui Cookie Dettagliata
const DetailedCookiePolicy = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Politica sui Cookie
    </Typography>
    <Typography variant="body1" paragraph>
      OverlineMenu utilizza esclusivamente cookie tecnici necessari per il
      funzionamento del servizio. I cookie utilizzati includono:
    </Typography>
    <ul>
      <Typography component="li" variant="body1">
        <strong>Cookie di Autenticazione:</strong> Utilizziamo cookie per
        gestire il login degli utenti tramite servizi di autenticazione come
        Google.
      </Typography>
      <Typography component="li" variant="body1">
        <strong>Cookie di Pagamento:</strong> Per elaborare i pagamenti
        elettronici, utilizziamo cookie forniti da servizi come Stripe.
      </Typography>
    </ul>
    <Typography variant="body1" paragraph>
      Questi cookie sono essenziali per il corretto funzionamento
      dell'applicazione e non possono essere disabilitati senza compromettere
      l'utilizzo del servizio. Non utilizziamo cookie di profilazione o di
      terze parti per scopi di marketing senza il consenso esplicito
      dell'utente.
    </Typography>
  </div>
);

// Minori e Verifica dell'Età
const MinorsAndAgeVerification = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Minori e Verifica dell'Età
    </Typography>
    <Typography variant="body1" paragraph>
      Se un utente minorenne richiede un prodotto vietato ai minori, come
      alcolici, è tenuto a dichiarare la propria maggiore età. Tuttavia, è
      responsabilità del locale che eroga il servizio verificare l'età
      dell'utente richiedendo un documento di identità valido, come previsto
      dalla legge. OverlineMenu non è responsabile per la verifica dell'età
      degli utenti finali.
    </Typography>
  </div>
);

// Conservazione dei Dati all'interno dell'Unione Europea
const DataRetentionInEU = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Conservazione dei Dati all'interno dell'Unione Europea
    </Typography>
    <Typography variant="body1" paragraph>
      I dati personali degli utenti sono conservati e trattati all'interno
      dell'Unione Europea. Non trasferiamo i dati personali degli utenti al di
      fuori dell'UE senza adottare adeguate misure di protezione in conformità
      con le normative vigenti.
    </Typography>
  </div>
);

// Limitazioni di Responsabilità
const LimitationsOfLiability = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Limitazioni di responsabilità
    </Typography>

    <Typography variant="body1" paragraph>
      L'utente riconosce e accetta che l'utilizzo dell'applicazione è a
      proprio rischio e pericolo. L'applicazione, i suoi proprietari,
      direttori, dipendenti e affiliati non saranno responsabili per danni
      diretti, indiretti, incidentali, speciali, consequenziali o esemplari,
      inclusi ma non limitati a, danni per perdita di profitti, avviamento,
      uso, dati o altre perdite intangibili, derivanti dall'uso o
      dall'impossibilità di utilizzare il servizio.
    </Typography>

    <Typography variant="body2" paragraph>
      <strong>L'applicazione non garantisce che:</strong>
    </Typography>
    <ul>
      <Typography component="li" variant="body2">
        Il servizio soddisferà le esigenze specifiche dell'utente;
      </Typography>
      <Typography component="li" variant="body2">
        Il servizio sarà ininterrotto, tempestivo, sicuro o privo di errori;
      </Typography>
      <Typography component="li" variant="body2">
        Qualsiasi informazione ottenuta tramite l'uso del servizio sarà
        accurata o affidabile;
      </Typography>
      <Typography component="li" variant="body2">
        L'applicazione sarà costante nel tempo: aggiornamenti futuri
        potrebbero modificare alcune funzionalità o l'interfaccia.
      </Typography>
    </ul>

    <Typography variant="body2" paragraph>
      L'utente è l'unico responsabile per qualsiasi danno al suo computer,
      dispositivo mobile o altri dispositivi o per la perdita di dati che
      potrebbe risultare dall'uso dell'applicazione.
    </Typography>
  </div>
);

// Diritto di Presentare Reclamo all'Autorità di Controllo
const RightToLodgeComplaint = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Diritto di Presentare Reclamo all'Autorità di Controllo
    </Typography>
    <Typography variant="body1" paragraph>
      Se l'utente ritiene che il trattamento dei suoi dati personali violi le disposizioni del GDPR, ha il diritto di presentare un reclamo all'Autorità Garante per la Protezione dei Dati Personali.
    </Typography>
  </div>
);

// Base Giuridica del Trattamento dei Dati
const LegalBasisForDataProcessing = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Base Giuridica del Trattamento dei Dati
    </Typography>
    <Typography variant="body1" paragraph>
      Trattiamo i dati personali degli utenti in base alle seguenti basi giuridiche:
    </Typography>
    <ul>
      <Typography component="li" variant="body1">
        <strong>Esecuzione di un contratto:</strong> Il trattamento è necessario per l'esecuzione del servizio richiesto dall'utente.
      </Typography>
      <Typography component="li" variant="body1">
        <strong>Obblighi legali:</strong> Il trattamento è necessario per adempiere a obblighi legali ai quali siamo soggetti.
      </Typography>
      <Typography component="li" variant="body1">
        <strong>Consenso dell'utente:</strong> Per finalità di marketing o altre attività per le quali viene richiesto esplicitamente il consenso dell'utente.
      </Typography>
      <Typography component="li" variant="body1">
        <strong>Interesse legittimo:</strong> Per migliorare il nostro servizio e garantire la sicurezza dell'applicazione.
      </Typography>
    </ul>
  </div>
);

// Clausola di Intero Accordo
const EntireAgreement = () => (
  <div>
    <Typography variant="h6" gutterBottom>
      Intero Accordo
    </Typography>
    <Typography variant="body1" paragraph>
      I presenti Termini e Condizioni costituiscono l'intero accordo tra l'utente e OverlineMenu riguardo all'utilizzo dell'applicazione e sostituiscono qualsiasi accordo o intesa precedente, orale o scritta.
    </Typography>
  </div>
);

// Clausola di Separabilità
const Severability = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Clausola di Separabilità
    </Typography>
    <Typography variant="body1" paragraph>
      Se una qualsiasi disposizione dei presenti Termini e Condizioni è ritenuta invalida o inapplicabile, tale disposizione sarà eliminata o limitata nella misura minima necessaria, e le restanti disposizioni rimarranno pienamente valide ed efficaci.
    </Typography>
  </div>
);


// Forza Maggiore
const ForceMajeure = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Forza Maggiore
    </Typography>
    <Typography variant="body1" paragraph>
      Non saremo responsabili per ritardi o mancata esecuzione dei nostri obblighi derivanti da eventi al di fuori del nostro controllo ragionevole, inclusi ma non limitati a, eventi naturali, guerre, atti terroristici, pandemie, interruzioni dei servizi internet o elettrici.
    </Typography>
  </div>
);

// Cessione dei Diritti
const Assignment = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Cessione dei Diritti
    </Typography>
    <Typography variant="body1" paragraph>
      L'utente non può cedere o trasferire i propri diritti o obblighi ai sensi dei presenti Termini e Condizioni senza il nostro previo consenso scritto. Possiamo cedere o trasferire i nostri diritti e obblighi a terzi in caso di fusione, acquisizione o vendita di tutti o parte dei nostri beni.
    </Typography>
  </div>
);

// Comunicazioni
const Communications = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Comunicazioni
    </Typography>
    <Typography variant="body1" paragraph>
      Per qualsiasi comunicazione relativa ai presenti Termini e Condizioni, gli utenti possono contattarci all'indirizzo email <a href="mailto:info@overlinemenu.it">info@overlinemenu.it</a>. Le comunicazioni ufficiali da parte nostra saranno inviate tramite email all'indirizzo fornito dall'utente o attraverso notifiche all'interno dell'applicazione.
    </Typography>
  </div>
);

// Lingua del Contratto
const ContractLanguage = () => (
  <div>
    <Typography variant="h6" gutterBottom sx={{ backgroundColor: "#efefef" }}>
      Lingua del Contratto
    </Typography>
    <Typography variant="body1" paragraph>
      I presenti Termini e Condizioni sono redatti in lingua italiana. In caso di traduzioni in altre lingue, la versione in lingua italiana prevarrà in caso di discrepanze o incongruenze.
    </Typography>
  </div>
);


// Termini e Condizioni principali
const TermsOfService = () => {
  return (
    <div style={{background:'white', padding:'5px'}}>
      <ResponsibilityForUserContent />
      <ModificationOfTerms />
      <TermsOfServiceUsage />
      <PriceVariation />
      <UserObligations />
      <RightToLodgeComplaint/>
      <UserRightsUnderGDPR/>
      <LegalBasisForDataProcessing/>
      <License />
      <IntellectualProperty />
      <WarrantyDisclaimer />
      <Indemnification />
      <Withdrawal />
      <Privacy />
      <GoverningLaw />
      <DataSecurity />
      <ThirdPartyServices />
      <DetailedCookiePolicy />
      <MinorsAndAgeVerification />
      <DataRetentionInEU />
      <LimitationsOfLiability />
      <ForceMajeure/>
      <Assignment/>
      <Communications/>
      <ContractLanguage/>

      {/* Privacy Policy */}
      <PrivacyPolicy />
      <EntireAgreement/>
      <Severability/>

      {/* Nota finale */}
      <Typography variant="body1" paragraph sx={{ color: "green" }}>
        Questo accordo è stato creato per proteggere sia gli utenti che
        l'applicazione. Non contiene clausole nascoste o termini ingannevoli.
      </Typography>
    </div>
  );
};

export default TermsOfService;
