import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Stack, Button, ButtonBase, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './Modal.css'
import RenderModal from './RenderModal'




function ModalConfirm(props) {
  const [openDefault, setOpenDefault] = useState(false)
  const [processing, setProcessing] = useState(false)

  const theme = useTheme();
  const { close = true, header, body, footer, confirmMessage = 'Conferma', onConfirm, open = openDefault,fullScreen=false, setOpen = setOpenDefault, onClick, children, ...rest } = props


  function handleOpen() {
    setOpen(true)
  }

  async function handleConfirm(confirmed) {
    if (confirmed) {
      setProcessing(true)
      const response = await onConfirm(true)
      if (response !== false) {
        setOpen(false)
      }

    } else {
      setOpen(false)
    }

    setProcessing(false)

  }

  function RenderFooter() {
    return (
      <Stack direction='row' justifyContent="space-around" alignItems="center">
        <Button sx={{marginRight:'5px'}} color={'secondary'} variant='contained' onClick={() => handleConfirm(false)}>Chiudi</Button>
        {footer}
        <Button id='button-confirm-action' color={'secondary'} variant='contained' onClick={() => handleConfirm(true)}>
          <span>
            {processing ? (
              <CircularProgress size={16} style={{ color: theme.palette.text.primary }} />
            ) : <span>
              {confirmMessage}
            </span>
            }
          </span>
        </Button>
      </Stack>
    )
  }



  return (
    <>
      {children &&
        <ButtonBase style={{ verticalAlign: 'top', width: '100%', height: '100%' }} onClick={handleOpen} {...rest}>
          {children}
        </ButtonBase>
      }

      <RenderModal header={header} body={body} footer={RenderFooter()} fullScreen={fullScreen} open={open} setOpen={setOpen} close={close} />


    </>
  )
}

export default ModalConfirm

ModalConfirm.propTypes = {
  onConfirm: PropTypes.func,
  confirmMessage: PropTypes.string,
  setOpen: PropTypes.func
}