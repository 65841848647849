import React, { useContext, useState, useEffect } from "react";

import Box from "@mui/material/Box";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import { UserContext } from "../../../../store/UserContext";
import { AlertApiContext } from "../../../../store/AlertApiContext";
import {
  Dialog,
  Typography,
  Grid,
  IconButton,
  Alert,
  Stack,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { BasketContext } from "../../../../store/BasketContext";
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";
import LoginButton from "../../../../components/Login/LoginButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  OrderRecapDetail,
  Button,
  safeRound,
} from "../../../../story-components/components";
import { Trans } from "react-i18next";

export default function ModalCheckBasket({
  open,
  setOpen,
  table_referenceFinded,
  localIsOpen,
  local_orders_mode,
  isStaff,
}) {
  const { setOpenBackDrop, logged, IsAllowed, localData } =
    useContext(UserContext);
  const { basket, setBasket, basketInfo } = useContext(BasketContext);

  const { api_post, enqueueSnackbar, api_get, api_request } =
    useContext(AlertApiContext);
  const { local_id, table_reference, local_name, order_token, order_id } =
    useParams();

  var header = {
    datetimeToken: new Date().toLocaleString(),
    table_reference: table_reference,
    local_id: local_id,
    local_name: localData.local_name,
    basket: basket,
  };

  const allowSendOrder =
    local_orders_mode >= 1 && logged && (local_orders_mode >= 2 || isStaff);
  //var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  const Buffer = require("buffer").Buffer;
  let basketOrderToken = Buffer.from(JSON.stringify(header)).toString("base64");

  const theme = useTheme();
  const [sendingOrder, setSendingOrder] = useState(false);
  const [user_phone, setUserPhone] = useState(null);
  const [allowOrder, setAllowOrder] = useState(true);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [frequencyTimes, setFrequencyTimes] = useState([]);
  const [frequencyTimesSelected, setFrequencyTimesSelected] =
    useState(undefined);
  const [cap, setcap] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [isOpen, setIsOpen] = useState(true);

  async function fetchFrequencyTimes() {
    if (table_reference === "Take Away" || table_reference === "Delivery") {
      const response = await api_get(
        `/api/get_frequency_times/${local_id}/frequency_times`
      );
      setFrequencyTimes(response.data.frequencyTimes);
    }
  }

  async function fetchUserServiceData() {
    const response = await api_get(
      `/api/get_user_service_data/${local_id}/${table_reference}`
    );
    if (response.status === "succcess") {
      setUserPhone(response.data.user_phone);
      setPhoneIsValid(
        phoneValues.textmask !== null && phoneValues.textmask.length === 14
      );
    } else {
      setAllowOrder(false);
    }
  }

  const [phoneValues, setPhoneValues] = React.useState({
    textmask: null,
    numberformat: "1320",
  });

  const handleChangeUserPhone = (event) => {
    setPhoneValues({
      ...phoneValues,
      [event.target.name]: event.target.value,
    });
    setUserPhone(event.target.value);
    setPhoneIsValid(
      phoneValues.textmask !== null && event.target.value.length === 14
    );
  };

  useEffect(() => {
    if (table_reference === "Delivery" || table_reference === "Take Away") {
      fetchUserServiceData();
      fetchFrequencyTimes();
      const timer = setTimeout(() => {
        fetchFrequencyTimes();
      }, 300000);
      return () => clearInterval(timer);
    }
  }, []);

  function validateRequest() {
    if (!localIsOpen) {
      enqueueSnackbar(
        "Ci dispiace, siamo chiusi non possiamo accettare ulteriori ordini, grazie",
        { variant: "warning" }
      );
      return { status: "error" };
    }
    if (table_reference === "Take Away" || table_reference === "Delivery") {
      if (!phoneIsValid) {
        enqueueSnackbar(
          "Devi inserire un numero di telefono valido per inviare l'ordine",
          { variant: "warning" }
        );
        return { status: "error" };
      } else if (frequencyTimesSelected === undefined) {
        enqueueSnackbar(
          "Devi selezionare una fascia oraria per procedere con l'ordine",
          { variant: "warning" }
        );
        return { status: "error" };
      }
    }
    if (table_reference === "Delivery") {
      if (cap.length !== 5) {
        enqueueSnackbar(
          "Devi inserire un cap valido per procedere con l'ordine",
          { variant: "warning" }
        );
        return { status: "error" };
      } else if (address.length < 4) {
        enqueueSnackbar(
          "Devi inserire un indirizzo valido per procedere con l'ordine",
          { variant: "warning" }
        );
        return { status: "error" };
      }
    }
    return { status: "success" };
  }

  async function onSendOrder() {
    if (validateRequest().status === "error") {
      return { status: "error" };
    }

    if (logged && table_referenceFinded) {
      setOpenBackDrop(true);
      setSendingOrder(true);
      const url = `/api/orders/post_order`;
      const body = {
        basket: basket[local_id],
        local_id: local_id,
        table_reference: table_reference,
        order_price: basketInfo[local_id].price,
        local_name: localData.local_name,
        frequency_time_selected: frequencyTimesSelected,
        user_phone: user_phone,
        cap: cap,
        city: city,
        address: address,
        order_tokenTemp: order_token,
        order_token: basketOrderToken,
        order_id: order_id,
      };
      const response = await api_post(url, body);
      if (response.status === "success") {
        setBasket({});
        setOpen(false);
        if (IsAllowed(local_id, ["MANAGER", "SENIOR", "STAFF"])) {
          navigate(`/orders/${local_id}`);
        } else {
          navigate(
            "/my_order/" +
              local_id +
              "/order_id/" +
              response.data.order_id
          );
        }
      } else {
        setSendingOrder(false);
        setOpenBackDrop(false);
      }
    } else {
      if (!logged) {
        enqueueSnackbar(
          "Non puoi effettuare un ordine se non hai effettuato il login",
          { variant: "warning" }
        );
      } else {
        enqueueSnackbar(
          "Non sei associato a nessun tavolo, se vuoi procedere con l'ordine chiedi un QRCODE al personale del locale",
          { variant: "warning" }
        );
      }
    }
  }

  async function onSendOrderNoAuth() {
    if (validateRequest().status === "error") {
      return { status: "error" };
    }
    const body = {
      basket: basket[local_id],
      order_price: basketInfo[local_id].price,
      local_id: local_id,
      local_name: localData.local_name,
      table_reference: table_reference,
    };
    const url = `/api/orders/post_order_no_auth`;
    const response = await api_request("POST", url, { body });

    if (response.status == "success") {
      if (IsAllowed(local_id, ["MANAGER", "SENIOR", "STAFF"])) {
        navigate(`/orders/${local_id}`);
      } else {
        navigate(
          `/my_order/${local_id}/order_token/${response.data.order_token}`
        );
      }
    }
  }

  function onDelete() {
    setBasket({});
    setOpen(false);
  }

  let navigate = useNavigate();

  useEffect(() => {
    if (open) {
      window.history.pushState("fake-route", document.title, "");
      window.addEventListener("popstate", () => setOpen(false));
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        closeAfterTransition
        aria-labelledby={"modal-modal-check-basket"}
        aria-describedby="modal-modal-product"
        onClose={() => setOpen(false)}
        fullScreen
        sx={{ zIndex: 1100 }}
      >
        <DialogTitle
          sx={{ background: theme.palette.primary.dark, color: "white" }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            style={{
              zIndex: "100",
              display: "inline-block",
              position: "fixed",
              top: 5,
              right: 5,
              width: "35px",
              height: "35px",
              padding: "5px",
              background: "rgb(0 0 0 / 15%)",
            }}
          >
            <CloseRoundedIcon style={{ color: "white" }} />
          </IconButton>
          {allowSendOrder ? (
            <Trans>Riepilogo Ordine</Trans>
          ) : (
            <Trans>Appunti</Trans>
          )}
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          {!localIsOpen && (
            <Alert severity="warning">
              Siamo chiusi, ci dispiace ma non puoi inviare ordini
            </Alert>
          )}
          {(local_orders_mode >= 2 || isStaff) &&
            local_orders_mode > 0 &&
            !table_referenceFinded && (
              <Alert severity="warning" sx={{ marginBottom: "15px" }}>
                {isStaff ? (
                  <Trans>
                    Non sei associato a nessun tavolo, seleziona il tavolo tra
                    le opzioni in basso a destra "Tavolo Qrcode"
                  </Trans>
                ) : (
                  <Trans>
                    Non sei associato a nessun tavolo, rivolgiti al personale
                    per chiedere un nuovo
                  </Trans>
                )}
                <QrCodeScannerRoundedIcon />
              </Alert>
            )}
          {local_orders_mode >= 2 && !logged && (
            <Alert severity="info" sx={{ marginBottom: "15px" }}>
              <Trans>
                Non hai effettuato il login, per salvare il tuo ordine e
                monitorarlo ti consigliamo di effettuare l'accesso tramite il
                pulsante sottostante prima di inviare l'ordine
              </Trans>{" "}
              <LoginButton />
            </Alert>
          )}
          <Stack
            className="card-style"
            sx={{ width: "100%", maxHeight: "90vh", overflowY: "auto" }}
          >
            <Box
              sx={{ boxShadow: 3, padding: "5px", backgroundColor: "white" }}
            >
              <OrderRecapDetail
                local_id={local_id}
                basket={basket}
                onChangeBasket={setBasket}
              />
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            padding: "16px",
            background: theme.palette.primary.dark,
            color: "white",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Typography
                  variant="h6"
                  style={{ whiteSpace: "normal", color: "white" }}
                >
                  <Trans>Totale</Trans>:
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    whiteSpace: "nowrap",
                    color: "white",
                    marginLeft: "10px",
                  }}
                  id="total-basket-amount"
                >
                  {basketInfo[local_id] &&
                    safeRound(basketInfo[local_id].price)}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    whiteSpace: "nowrap",
                    color: "white",
                    marginLeft: "2px",
                  }}
                  id="total-basket-currency"
                >
                  €
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                sx={{ width: "100%" }}
                spacing={2}
              >
                <Button
                  id='button-clear-basket'
                  color="error"
                  variant="contained"
                  onClick={() => onDelete()}
                  startIcon={<DeleteIcon />}
                >
                  <Trans>Svuota</Trans>
                </Button>
                {(local_orders_mode >= 2 || isStaff) &&
                  local_orders_mode > 0 &&
                  !logged && (
                    <Button
                      sx={{
                        backgroundColor: table_referenceFinded
                          ? "white"
                          : "grey",
                        color: "black",
                      }}
                      variant="contained"
                      startIcon={<SendIcon />}
                      onClick={() => onSendOrderNoAuth()}
                    >
                      <Trans>Invia senza autenticazione</Trans>
                    </Button>
                  )}
                {table_referenceFinded &&
                (table_reference === "Take Away" ||
                  table_reference === "Delivery") ? (
                  <Button
                    sx={{
                      color:
                        logged && table_referenceFinded
                          ? theme.palette.text.contrastSecondary
                          : "black",
                      backgroundColor:
                        logged && table_referenceFinded
                          ? theme.palette.secondary.main
                          : "grey",
                    }}
                    variant="contained"
                    onClick={() =>
                      navigate(
                        `/local/checkout/${local_id}/${local_name}/${table_reference}`
                      )
                    }
                    startIcon={
                      <SendIcon
                        sx={{
                          color:
                            logged && table_referenceFinded
                              ? theme.palette.text.contrastSecondary
                              : "black",
                        }}
                      />
                    }
                  >
                    <Trans>Prosegui</Trans>
                  </Button>
                ) : (
                  allowSendOrder && (
                    <Button
                      sx={{
                        color:
                          logged && table_referenceFinded
                            ? theme.palette.text.contrastSecondary
                            : "black",
                        backgroundColor:
                          logged && table_referenceFinded
                            ? theme.palette.secondary.main
                            : "grey",
                      }}
                      variant="contained"
                      onClick={() => onSendOrder()}
                      startIcon={
                        <SendIcon
                          sx={{
                            color:
                              logged && table_referenceFinded
                                ? theme.palette.text.contrastSecondary
                                : "black",
                          }}
                        />
                      }
                    >
                      <Trans>Invia</Trans>
                    </Button>
                  )
                )}
              </Stack>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
