import React, { useState, useContext } from 'react';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import { Fab, Badge, Zoom } from '@mui/material';


import { UserContext } from '../../../store/UserContext'
import { BasketContext } from '../../../store/BasketContext';
/*import {handleResponse} from '../../api/api'*/
import SpeedDialLocal from './SpeedDialLocals';
import { styled } from '@mui/material/styles';

//icons
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ModalCheckBasket from '../Local/Forms/ModalCheckBasket';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from "react-router-dom";
import { ShareButton } from '../../../story-components/components'
import SettingsIcon from '@mui/icons-material/Settings';
import EditNoteIcon from '@mui/icons-material/EditNote';
import LanguageIcon from '@mui/icons-material/Language';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Trans } from "react-i18next";
import { domain } from '../../../configs'
import EventIcon from '@mui/icons-material/Event';
import LanguageModal from './LanguageModal';  // Aggiungi questo import



const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: 2,
        border: `1px solid white`,
        padding: '0 0px',
        color: 'white',
        backgroundColor: theme.palette.secondary.dark
    },
}));



export default function FooterLocalActions({ local_id, local_name, setOpenFiltersMenu, table_referenceFinded, local_orders_mode, localIsOpen, isStaff }) {
    const { logged, userNotifications, localData } = useContext(UserContext);
    const { basketInfo } = useContext(BasketContext);
    const [openCheckBasket, setOpenCheckBasket] = useState(false)
    const [openLanguage, setOpenLanguage] = useState(false)
    const theme = useTheme();
    let navigate = useNavigate()
    console.log('userNotifications:',userNotifications)



    function getOrdersNotifications() {
        try {
            return userNotifications.activeOrders[local_id].orders  || 0;
        } catch {
            return 0
        }
    }

    function getContactsNotifications() {
        try {
            return userNotifications.notifyUnseenContacts[local_id].contactsUnseen  || 0;
        } catch {
            return 0
        }
    }
    const ordersNotifications = getOrdersNotifications()
    const contactsNotifications = getContactsNotifications()
    const notifications = ordersNotifications + contactsNotifications




    function getSpeedDialActions() {
        if (isStaff) {
            const speedDialActionsManager = [
                { key: 'edit_local', icon: <SettingsIcon />, name: <Trans>Impostazioni Locale</Trans>, onClick: () => navigate('/edit_local/' + local_id) },
                { key: 'edit_menu', icon: <EditIcon />, name: <Trans>Modifica Menù</Trans>, onClick: () => navigate('/local/edit_menu/' + local_id) },
                { key: 'filter_menu', icon: <FilterAltIcon />, name: <Trans>Filtri Menù</Trans>, onClick: () => setOpenFiltersMenu(true) },
                { key: 'share_menu', icon: <ShareButton domain={domain} shareUrl={'/local/Menu/' + local_id} />, name: <Trans>Condividi</Trans>, onClick: () => console.log('open share') },
                { key: 'qrcode_menu', icon: <QrCode2Icon />, name: <Trans>Tavolo Qrcode</Trans>, onClick: () => navigate('/qrcode_generator/' + local_id  + '/table') },
                { key: 'handle_orders', icon: <AssignmentTurnedInIcon />, name: <Trans>Gestione ordini</Trans>, onClick: () => navigate('/orders/' + local_id + '/' + local_name), badge: ordersNotifications },
                { key: 'handle_bookings', icon: <EventIcon />, name: <Trans>Prenotazioni</Trans>, onClick: () =>  navigate('/booking_management/' + local_id), badge: ordersNotifications },
                { key: 'Contatti', icon: <ContactSupportIcon />, name: <Trans>Contatti</Trans>, onClick: () =>  navigate('/local/contact_us/' + local_id, {state: { from: "/local/Menu/:local_id/:table_reference" }}), hide:(localData.plan_value<=1), badge: contactsNotifications}
            ];
            return speedDialActionsManager

        } else {
            const speedDialActionsCustomer = [
                { key: 'Orari', icon: <AccessTimeIcon />, name: <Trans>Orari</Trans>,     
                onClick: () =>  navigate(`/local_landing_page/${local_id}`, {state: { from: "/local/Menu/:local_id/:table_reference" }}), 
                    hide:( localData.plan_value<=1) },
                { key: 'Contattaci', icon: <ContactSupportIcon />, name: <Trans>Contattaci</Trans>, onClick: () =>  navigate('/local/contact_us/' + local_id, {state: { from: "/local/Menu/:local_id/:table_reference" }}), hide:(localData.plan_value<=1 || localData.contactFormStatus!==true)},
                { key: 'language_menu', icon: <LanguageIcon />, name: <Trans>Lingua</Trans>, onClick: () => setOpenLanguage(true) },
                { key: 'filter_menu', icon: <FilterAltIcon />, name: <Trans>Filtri Menù</Trans>, onClick: () => setOpenFiltersMenu(true) },
                { key: 'share_menu', icon: <ShareButton domain={domain} shareUrl={'/local/Menu/' + local_id } />, name: <Trans>Condividi</Trans>, onClick: () => console.log('open share') },
            ];
            return speedDialActionsCustomer

        }
    }

    const allowSendOrder = local_orders_mode >= 1 && logged && (local_orders_mode >= 2 || isStaff)


    return (
        <Box>
            <LanguageModal open={openLanguage} setOpen={setOpenLanguage} />

            <ModalCheckBasket open={openCheckBasket} setOpen={setOpenCheckBasket} table_referenceFinded={table_referenceFinded} local_orders_mode={local_orders_mode} localIsOpen={localIsOpen} isStaff={isStaff} />
            <Box sx={{ position: 'fixed', bottom: '5px', right: '5px' }}>
                <StyledBadge badgeContent={undefined} color='primary' sx={{zIndex:1000}}>
                    <SpeedDialLocal actions={getSpeedDialActions()} counterNotifications={notifications} />
                </StyledBadge>
            </Box>
            <Zoom
                timeout={500}
                in={basketInfo[local_id] && basketInfo[local_id].quantity > 0}
                style={{
                    transitionDelay: `${100}ms`,
                }}
                unmountOnExit
            >

                <Box sx={{ position: 'fixed', bottom: '25px', left: '25px' }}>

                    <Fab id='basket-modal-button' style={{ backgroundColor: theme.palette.secondary.main }} aria-label="edit" onClick={() => setOpenCheckBasket(true)}                 >
                        <StyledBadge badgeContent={basketInfo[local_id] && basketInfo[local_id].quantity} color='primary'>
                            {allowSendOrder ?
                                <ShoppingCartIcon style={{ color: theme.palette.text.contrastSecondary }} />
                                :
                                <EditNoteIcon style={{ color: theme.palette.text.contrastSecondary }} />
                            }
                        </StyledBadge>
                    </Fab>

                </Box>
            </Zoom>

        </Box>




    )
}

