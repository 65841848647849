import React from "react";

const sectionStyle = {
  background: "#f5f5f5",
  padding: "10px",
  borderRadius: "5px",
  marginBottom: "10px",
  textAlign: "justify",
};

const PrivacyPolicy = () => {
  const sectionStyle = {
    marginBottom: "20px"
  };

  return (
    <div
      style={{
        background: "white",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        color: "#333",
      }}
    >
      <h3>Politica sulla privacy</h3>

      <p>
        <b>Utilizziamo cookie o tecnologie simili per finalità tecniche</b>,
        necessarie per fornire i servizi richiesti, come il login e la gestione
        degli acquisti in app.
      </p>
      <p>
        Per avere informazioni circa i tuoi dati personali raccolti, le finalità
        ed i soggetti con cui i dati vengono condivisi, contatta il Titolare{" "}
        <b>info@overlinemenu.it</b>. Per avere più informazioni e conoscere i
        tuoi diritti puoi anche visualizzare la versione completa di questa
        privacy policy.
      </p>

      {/* Sezione Tipologie di Dati raccolti */}
      <section style={sectionStyle}>
        <h4>Tipologie di Dati raccolti</h4>
        <p>
          Il trattamento dei dati è effettuato su servizi cloud. Dettagli
          completi su ciascuna tipologia di Dati Personali raccolti sono forniti
          nelle sezioni dedicate di questa privacy policy o mediante specifici
          testi informativi visualizzati prima della raccolta dei Dati stessi. I
          Dati Personali possono essere liberamente forniti dall'Utente o, nel
          caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di
          questa Applicazione.{" "}
          <b>
            Se non diversamente specificato, tutti i Dati richiesti da questa
            Applicazione sono obbligatori
          </b>
          . Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per
          questa Applicazione fornire il Servizio. Nei casi in cui questa
          Applicazione indichi alcuni Dati come facoltativi, gli Utenti sono
          liberi di astenersi dal comunicare tali Dati, senza che ciò abbia
          alcuna conseguenza sulla disponibilità del Servizio o sulla sua
          operatività. Gli Utenti che dovessero avere dubbi su quali Dati siano
          obbligatori sono incoraggiati a contattare il Titolare.{" "}
          <b>L’eventuale utilizzo di Cookie</b> - o di altri strumenti di
          tracciamento - da parte di questa Applicazione o dei titolari dei
          servizi terzi utilizzati da questa Applicazione ha la finalità di
          fornire il Servizio richiesto dall'Utente, oltre alle ulteriori
          finalità descritte nel presente documento e nella Cookie Policy.
          L'Utente si assume la responsabilità dei Dati Personali di terzi
          ottenuti, pubblicati o condivisi mediante questa Applicazione.
        </p>
      </section>

      {/* Sezione Modalità e luogo del trattamento dei Dati raccolti */}
      <section style={sectionStyle}>
        <h4>Modalità e luogo del trattamento dei Dati raccolti</h4>
        <h5>Modalità di trattamento</h5>
        <p>
          I dati personali degli utenti vengono trattati utilizzando misure di sicurezza adeguate per proteggere queste informazioni, tra cui l'utilizzo di servizi cloud affidabili e rinomati, connessione HTTPS, e limitazione dell'accesso ai soli amministratori autorizzati. Le password non vengono salvate, poiché utilizziamo servizi di autenticazione di Google.
        </p>
        <h5>Luogo</h5>
        <p>
          I Dati sono trattati presso le sedi operative del Titolare ed in ogni
          altro luogo in cui le parti coinvolte nel trattamento siano
          localizzate. Per ulteriori informazioni, contatta il Titolare.{" "}
          <b>
            I Dati Personali dell’Utente potrebbero essere trasferiti in un
            paese diverso da quello in cui l’Utente si trova
          </b>
          . Per ottenere ulteriori informazioni sul luogo del trattamento
          l’Utente può fare riferimento alla sezione relativa ai dettagli sul
          trattamento dei Dati Personali.
        </p>
        <h5>Periodo di conservazione</h5>
        <p>
          I dati personali degli utenti saranno conservati per il tempo necessario a soddisfare le finalità per cui sono stati raccolti e fino a quando l'utente richiede la cancellazione, ove possibile. Potrebbero essere conservati per un periodo più lungo a causa di eventuali obbligazioni legali o sulla base del consenso degli Utenti.
        </p>
      </section>

      {/* Sezione Cookie Policy */}
      <section style={sectionStyle}>
        <h4>Cookie Policy</h4>
        <p>
          Utilizziamo cookie e tecnologie affini esclusivamente per garantire
          funzionalità essenziali, come il login e la gestione degli ordini.{" "}
          <b>I cookie utilizzati sono esclusivamente tecnici</b>. Nel caso in
          cui venga utilizzato un cookie non tecnico, verrà richiesto un
          consenso esplicito all'utente prima dell'installazione del cookie.
        </p>
        <p>
          Inoltre, analizziamo i tuoi dati per ottimizzare il nostro servizio.{" "}
          <b>
            Queste informazioni, collegate agli account, sono usate solo
            internamente
          </b>
          . Il consenso specifico per l'analisi statistica sarà richiesto
          separatamente.
        </p>
      </section>

      {/* Sezione Utilizzo delle informazioni */}
      <section style={sectionStyle}>
        <h4>Utilizzo delle informazioni</h4>
        <p>
          <strong>Utilizziamo queste informazioni per:</strong>
          <ul>
            <li>Fornire, mantenere e migliorare il nostro servizio</li>
            <li>Processare transazioni e inviare notifiche relative alle transazioni</li>
            <li>Rispondere a richieste o domande degli utenti</li>
            <li>Inviare aggiornamenti sul servizio e messaggi promozionali, solo con il consenso dell'utente</li>
            <li>Monitorare la sicurezza e la stabilità del servizio</li>
            <li>Analizzare e migliorare i servizi offerti</li>
          </ul>
        </p>
      </section>

      {/* Sezione Base giuridica del trattamento */}
      <section style={sectionStyle}>
        <h4>Base giuridica del trattamento</h4>
        <ul>
          <li>Consenso dell'utente</li>
          <li>Esecuzione di un contratto</li>
          <li>Obblighi legali</li>
          <li>Legittimi interessi dell'azienda</li>
        </ul>
      </section>

      {/* Sezione Salvataggio delle informazioni */}
      <section style={sectionStyle}>
        <h4>Salvataggio delle informazioni</h4>
        <p>
          Le informazioni personali degli utenti vengono salvate in chiaro nel nostro database. Utilizziamo servizi cloud affidabili e rinomati per garantire la sicurezza dei dati e limitare l'accesso ai soli amministratori autorizzati. La connessione è protetta tramite HTTPS. Le password non vengono salvate, poiché utilizziamo servizi di autenticazione di Google.
        </p>
      </section>

      {/* Sezione Condivisione dei dati */}
      <section style={sectionStyle}>
        <h4>Condivisione dei dati</h4>
        <p>
          I dati personali degli utenti possono essere condivisi solo con:
          <ul>
            <li>Fornitori di servizi terzi che supportano l'azienda nella fornitura dei servizi, come Stripe per la gestione dei pagamenti</li>
            <li>Attività commerciali contattate dall'utente, per fornire i servizi richiesti</li>
            <li>Autorità competenti, se richiesto dalla legge</li>
          </ul>
          I dati in chiaro non vengono condivisi con nessun altro.
        </p>
      </section>

      {/* Sezione Diritti degli utenti */}
      <section style={sectionStyle}>
        <h4>Diritti degli utenti</h4>
        <ul>
          <li>Accedere ai propri dati personali</li>
          <li>Richiedere la rettifica o la cancellazione dei propri dati personali</li>
          <li>Opporsi al trattamento dei propri dati personali</li>
          <li>Richiedere la limitazione del trattamento dei propri dati personali</li>
          <li>Richiedere la portabilità dei propri dati personali</li>
        </ul>
        Per esercitare questi diritti, gli utenti possono contattarci all'indirizzo email: overline.b@gmail.com.
      </section>

      {/* Sezione Diritto all'oblio */}
      <section style={sectionStyle}>
        <h4>Diritto all'oblio</h4>
        <p>
          Gli utenti hanno il diritto di richiedere la cancellazione dei propri dati personali in qualsiasi momento. Per fare ciò, possono contattarci all'indirizzo email: overline.b@gmail.com. Dopo aver ricevuto la richiesta, valuteremo la stessa in base ai criteri stabiliti dal GDPR e, se applicabile, procederemo alla cancellazione dei dati personali entro i termini previsti dalla legge. Informeremo l'utente una volta completata la cancellazione.
        </p>
      </section>

      {/* Sezione Contatti per la Privacy */}
      <section style={sectionStyle}>
        <h4>Contatti per la Privacy</h4>
        <p>
          Per domande o reclami relativi alla privacy, gli utenti possono contattarci all'indirizzo email: overline.b@gmail.com.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;

