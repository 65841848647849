import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Reorder } from "framer-motion";
import { ReorderMenuCard } from "./ReorderMenuCard";
import { Box, Divider, Button, Grid } from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SectionEditDialog from "./SectionEditDialog";
import ReorderSubSections from "./sub-section/ReorderSubSection";
import Locker from "../Forms/Locker";

function ReorderSection(props) {
  const {
    sectionProducts,
    subSection,
    section_name,
    section_id,
    section_cover,
    section_card_color,
    section_description,
    section_color,
    onChange,
    onEdit,
    onDelete,
    onChangeSubSection,
    onEditSubSection,
    onDeleteSubSection,
    onAddProduct,
    onChangeSectionName,
    onChangeSectionCover,
    onChangeSectionDescription,
    onChangeSectionColor,
    onChangeSectionCardColor,
    onDeleteSection,
    noMatchProductsId,
    children,
    planValue,
    ...rest
  } = props;
  const [items, setItems] = useState(sectionProducts);
  const [hideProducts, setHideProducts] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  function handleDrag(newItems) {
    setItems(newItems);
    onChange(newItems);
  }

  const handleSectionChanges = (values) => {
    if (values.newSectionName) {
      onChangeSectionName(values.newSectionName);
    }
    if (values.newSectionCover) {
      onChangeSectionCover(values.newSectionCover);
    }
    if (values.newSectionDescription) {
      onChangeSectionDescription(values.newSectionDescription);
    }
    if (values.newSectionColor) {
      onChangeSectionColor(values.newSectionColor);
    }
    if (values.newSectionCardColor) {
      onChangeSectionCardColor(values.newSectionCardColor);
    }
  };

  useEffect(() => {
    // Controlla se tutti i prodotti sono nascosti
    const allHidden = sectionProducts.every((item) => item.product_hidden);

    // Se tutti i prodotti sono nascosti, imposta hideProducts su false
    if (allHidden) {
      setHideProducts(false);
    }
  }, [items]); // Dipendenze vu

  function hideAllSectionProducts() {
    for (let item of sectionProducts) {
      item.product_hidden = hideProducts;
    }
    setHideProducts(!hideProducts);
    onChange([...sectionProducts]);
  }

  return (
    <Box style={{ paddingBottom: "100px", zIndex: 2 }}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <Button
            style={{ height: "100%" }}
            fullWidth
            startIcon={<AddCircleIcon />}
            variant="contained"
            onClick={() => onAddProduct({ section_name: section_name })}
          >
            Aggiungi prodotti
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Locker
            locked={planValue < 1}
            message={
              "La possibilità di nascondere i prodotti è disponibile dal piano Starter in poi."
            }
          >
            <Button
              style={{ height: "100%" }}
              onClick={hideAllSectionProducts}
              fullWidth
              startIcon={
                hideProducts ? (
                  <VisibilityOffIcon />
                ) : (
                  <RemoveRedEyeOutlinedIcon />
                )
              }
              color="secondary"
              variant="contained"
            >
              {hideProducts ? "Nascondi prodotti" : "Mostra prodotti"}
            </Button>
          </Locker>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            id='button-edit-section'
            style={{ height: "100%" }}
            fullWidth
            startIcon={<EditRoundedIcon />}
            variant="contained"
            onClick={handleDialogOpen}
          >
            Modifica sezione
          </Button>
        </Grid>
      </Grid>
      <SectionEditDialog
        open={openDialog}
        onClose={handleDialogClose}
        section_name={section_name}
        section_cover={section_cover}
        section_color={section_color}
        section_card_color={section_card_color}
        section_description={section_description}
        onDeleteSection={() => onDeleteSection(section_id)}
        onChange={handleSectionChanges}
        planValue={planValue}
      />

      <Divider sx={{ marginTop: "10px" }} />

      <ReorderSubSections
        subSection={subSection}
        onChange={onChangeSubSection}
        onEdit={onEditSubSection}
        onDelete={onDeleteSubSection}
      />

      <Divider sx={{ marginTop: "10px" }} />

      <Reorder.Group
        id='reorder-group'
        style={{ padding: "0px" }}
        axis="y"
        values={items}
        onReorder={handleDrag}
      >
        {items.map((item, index) => (
          <ReorderMenuCard
            key={"reorder-" + item.product_id}
            style={{
              filter:
                noMatchProductsId?.product_ids?.includes(item.product_id) &&
                "brightness(0.7)",
              backgroundColor:
                noMatchProductsId?.product_ids?.includes(item.product_id) &&
                "#00000017",
            }}
            index={index}
            section_card_color={section_card_color}
            item={item}
            subSection={subSection}
            setItems={setItems}
            onEdit={onEdit}
            onChange={onChange}
            items={items}
            planValue={planValue}
          />
        ))}
      </Reorder.Group>
    </Box>
  );
}

export default ReorderSection;

ReorderSection.propTypes = {
  tables: PropTypes.array,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onChangeTable: PropTypes.func,
  onChangeSectionName: PropTypes.func,
  onDeleteSection: PropTypes.func,
  onAddProduct: PropTypes.func,
  onChangeSectionCover: PropTypes.func,
  onChangeSectionDescription: PropTypes.func,
  onChangeSectionColor: PropTypes.func,
  onChangeSectionCardColor: PropTypes.func,
};
