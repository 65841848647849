import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  Stack,
  Alert,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { AlertApiContext } from "../../../../store/AlertApiContext";
import { UserContext } from "../../../../store/UserContext";
import ReactMarkdown from "react-markdown";
import {
  LoadingAnimations,
  ModalButton,
} from "../../../../story-components/components";
import { useNavigate } from "react-router-dom";
import Particelles from "./AiBackground";
import CustomAiAssistantForm from "./CustomAiAssistantForm";
import AIProductCard from "./components/AIProductCard";

const initialBotMessage = `
**Ciao, sono OverAI!** 🤖✨  
Sono qui per assisterti nella gestione della tua attività. Ecco cosa posso fare per te:

- **Gestione Prodotti**: Posso aggiungere nuovi prodotti al tuo menù. Per esempio, chiedimi di suggerire "5 vini piemontesi da abbinare con del pesce" e successivamente, se ti piacciono, posso inserirli direttamente nel menù per te. Tutti i prodotti che aggiungerò saranno nascosti, perchè necessitano prima di una tua supervisione  e conferma che potrai fare direttamente nella sezione "Modifica Menù".

- **Analisi del Menù**: Posso analizzare il tuo menù attuale e fornire suggerimenti mirati. Ad esempio, potrei indicarti quali piatti sono meno popolari o suggerirti modifiche basate sulle preferenze dei clienti, come "introdurre un'opzione vegetariana per il tuo piatto più venduto".

- **Food Pairing**: Offro abbinamenti di cibo innovativi. Chiedimi ad esempio di "trovare il miglior cocktail da servire con il tuo antipasto di gamberi", per sorprendere i tuoi ospiti con combinazioni uniche.

- **Creazione Menù**: Aiuto a progettare menù unici ed eccezionali. Potresti chiedermi di "creare un menù a tema mediterraneo per l'estate", completo di piatti e bevande.

- **Ricette**: Posso aiutarti a creare ricette dettagliate. Immagina di voler incorporare ingredienti di stagione; chiedimi "come posso usare i pomodori freschi in una ricetta innovativa?", e ti fornirò ricette dettagliate e consigli di preparazione.

- **Segnalazioni**: Posso aiutarti ad aprire segnalazioni o suggerimenti al team di sviluppo se vuoi nuove feature o riscontri dei problemi.


Sono qui per rendere tutto più semplice e veloce. Dimmi, come posso assisterti oggi?

**Nota Bene**: 
- La precisione delle tue richieste migliora la qualità delle mie risposte. Specifica dettagli come la sezione del menù in cui desideri inserire un prodotto, altrimenti procederò secondo il mio criterio.
- Evita di condividere informazioni sensibili, come password o dati personali, per garantire la sicurezza e la privacy.

`;

function AiChat() {
  const { local_id } = useParams();
  const { api_request } = useContext(AlertApiContext);

  const { setDefaultTheme, isAdmin, localData } = useContext(UserContext);
  useEffect(() => {
    setDefaultTheme();
  }, []);

  const [messages, setMessages] = useState([
    { text: initialBotMessage, author: "bot" },
  ]);

  const [thread, setThread] = useState(undefined);
  const [threads, setThreads] = useState([]);

  const [systemTyping, setSystemTyping] = useState(false);
  const messagesEndRef = useRef(null); // Riferimento all'ultimo messaggio

  let navigate = useNavigate();
  const inputRef = useRef(""); // Usa useRef invece di useState

  async function getNewThread() {
    const queryParams = { local_id };
    const response = await api_request("GET", `/api/ai/create-new-ai-chat`, {
      queryParams,
    });
    setThread(response.data.thread);
  }

  useEffect(() => {
    getNewThread();
  }, []);

  useEffect(() => {
    if (messagesEndRef.current && systemTyping) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [systemTyping]);

  const systemMsgStyle = {
    padding: "30px 20px 10px 20px",
    backgroundColor: "rgb(243 243 243 / 80%)",
    borderRadius: "20px 20px 20px 0px",
    display: "inline-block",
    maxWidth: "fit-content",
    color: "black",
    margin: "0px 20px 0px 0px",
  };

  const userMsgStyle = {
    padding: "20px 20px 0px 20px",
    backgroundColor: "rgb(17 29 70 / 80%)",
    borderRadius: "20px 20px 0px 20px",
    display: "inline-block",
    maxWidth: "fit-content",
    alignSelf: "flex-end",
    color: "white",
    margin: "0px 0px 0px 20px",
  };

  async function handleSend() {
    const input = inputRef.current.value;
    if (input.trim()) {
      setMessages([...messages, { text: input, author: "user" }]);
      // Simulare una risposta del bot
      const queryParams = { local_id };
      const body = { user_message: input, thread };
      setSystemTyping(true);
      inputRef.current.value = "";
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      const response = await api_request("POST", `/api/ai/send_message`, {
        queryParams,
        body,
      });
      setSystemTyping(false);

      if (response.data) {
        setMessages((msgs) => [
          ...msgs,
          {
            text: response.data.response_message,
            author: "bot",
            contents: response.data.contents,
          },
        ]);
      } else {
        setMessages((msgs) => [
          ...msgs,
          {
            text: "Non è stato possibile proseguire con la chat, controlla la tua connessione oppure il serivizio potrebbe essere temporaneamente non disponibile. Ti invitiamo a riprovare più tardi.",
            author: "bot",
          },
        ]);
      }
    }
  }

  const handleMessageKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  // retrive threads
  // Funzione per ottenere i thread esistenti
  async function getThreads() {
    const queryParams = { local_id };
    const response = await api_request("GET", `/api/ai/retrive-ai-threads`, {
      queryParams,
    });
    setThreads(response.data.threads);
  }

  // Esegui la chiamata per ottenere i thread esistenti al caricamento del componente
  useEffect(() => {
    if (isAdmin) {
      getThreads();
    }
  }, [isAdmin]);

  // Funzione per gestire il recupero dei messaggi di un thread selezionato
  async function handleThreadSelection(threadId) {
    setThread(threadId);
    const queryParams = { local_id, threadId };
    const response = await api_request("GET", `/api/ai/retrive-ai-chats`, {
      queryParams,
    });
    setMessages(response.messages);
  }
  if (localData.plan_value > 1 || isAdmin) {
    return (
      <Box>
        <Box
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
        >
          <Particelles />
        </Box>
        <Stack direction="column" sx={{ maxHeight: "calc(100vh - 64px)" }}>
          {isAdmin && (
            <Box
              sx={{
                padding: "5px",
                maxHeight: "15vh",
                minHeight: "100px",
                overflowY: "auto",
                backgroundColor: "rgb(243 243 243 / 80%)",
                boxShadow: 3,
              }}
            >
              <ModalButton body={<CustomAiAssistantForm />}>
                <Button variant="contained">Istruzioni assistente</Button>
              </ModalButton>
              {threads.length > 0 && (
                <List>
                  {threads.map((threadId) => (
                    <ListItem key={threadId}>
                      <ListItemButton
                        onClick={() => handleThreadSelection(threadId)}
                      >
                        {threadId}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          )}
          {console.log("is admin:", isAdmin)}

          <Box
            sx={{
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              padding: "5px",
              height: "calc(100vh - 64px)",
            }}
          >
            {messages.map((msg, index) => (
              <Paper
                key={index}
                elevation={3}
                sx={msg.author === "bot" ? systemMsgStyle : userMsgStyle}
              >
                <Typography variant="body1" sx={{ fontFamily: "system-ui" }}>
                  <ReactMarkdown>{msg.text}</ReactMarkdown>
                </Typography>
                {msg.contents?.length > 0 &&
                  msg.contents.map((item, index) => {
                    if (item.new_product) {
                      return (
                        <AIProductCard
                          key={item.new_product.product_id}
                          product={item}
                        />
                      );
                    }
                  })}
              </Paper>
            ))}
            {systemTyping && (
              <div
                style={{
                  marginBottom: "8px",
                  display: "flex",
                  justifyContent: "flex-start",
                  marginLeft: "0px",
                  marginRight: "30px",
                }}
              >
                <Paper elevation={3} style={systemMsgStyle}>
                  <LoadingAnimations />
                </Paper>
              </div>
            )}

            <Button
              fullWidth
              color="secondary"
              onClick={() => navigate(`/local/edit_menu/${local_id}`)}
              variant="contained"
            >
              Vai a modifica menù
            </Button>
            <div ref={messagesEndRef} />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                padding: "5px",
              }}
            >
              <TextField
                sx={{
                  backgroundColor: "rgb(243 243 243 / 80%)",
                  borderRadius: "5px",
                  borderColor: "white",
                  fontFamily: "system-ui",
                }}
                fullWidth
                multiline
                variant="outlined"
                inputRef={inputRef} // Uso di inputRef
                onKeyDown={handleMessageKeyDown}
                placeholder="Scrivi un messaggio..."
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSend}
              >
                Invia
              </Button>
            </Box>
          </Box>
        </Stack>
      </Box>
    );
  } else {
    return (
      <Stack direction="column" spacing={3} sx={{ marginTop: "10px" }}>
        <Alert severity="info">Non puoi accedere a questa pagina</Alert>
        <Button
          fullWidth
          onClick={() => navigate(`/local/Menu/${local_id}`)}
          variant="contained"
        >
          Vai al menù
        </Button>
      </Stack>
    );
  }
}

export default AiChat;
